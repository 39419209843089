.synthesizer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 100px;
    bottom: 10px;
    overflow: hidden;
  }
  .synthesizer-icon-container {
    @apply relative cursor-pointer text-neutral-500 dark:text-white dark:bg-black border-solid border-2 border-neutral-200 dark:border-white rounded-full p-4 w-20 h-20;
  }
  .synthesizer-icon-container.playing-sound-animation {
    @apply bg-primary text-white border-primary-light border-2;
  }
  .synthesizer-icon-container.playing-sound-animation::before {
    width: 90px;
    height: 90px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.4);
    border-radius: 50%;
    animation: listening infinite 1.5s;
  }
  .synthesizer-icon-container.playing-sound-animation::before {
    @apply border-primary-light border-2;
  }
  @keyframes listening {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    95% {
      opacity: 0.05;
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
  .synthesizer-icon {
    width: 100% !important;
    height: 100% !important;
  }
  .synthesizer-status {
    font-size: 17px;
    margin-right: 20px;
  }
  .btn {
    position: relative;
    border: none;
    padding: 14px;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    border-radius: 25px;
    box-shadow: 0px 0px 10px 5px #ffffff1a;
    z-index: 90;
    transition: all 0.5s;
  }
  .btn {
    @apply text-neutral-500 dark:text-white border-solid border-2 border-neutral-200 dark:border-white;
  }
  .synthesizer-result-container {
    text-align: center;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }
  .synthesizer-result-text {
    margin-bottom: 30px;
    width: 70vw;
    overflow-y: auto;
  }
  .synthesizer-reset {
    display: flex;
    align-items: center;
  }
  
  .animate-tap {
    animation: animateTappedButton 0.7s forwards;
  }
  @keyframes animateTappedButton {
    0% {
      @apply bg-auto;
    }
    50% {
      @apply bg-primary text-white;
    }
    100% {
      @apply bg-auto;
    }
  }
  