.stop-control-btn,
.play-control-btn{
    @apply w-20 h-20 mt-5
}
.stop-control-btn,
.play-control-btn{
  @apply bg-secondary rounded-full
}
.stop-control-btn .MuiSvgIcon-root,
.play-control-btn .MuiSvgIcon-root{
    @apply w-full h-full text-white
}

.pulse-animation{
  animation: pulseAnimation 2s infinite;
}
@keyframes pulseAnimation {
  0%{transform: scale(1);}
  50%{transform: scale(1.05);}
  100%{transform: scale(1);}
}



/* PROGRESS BAR */

.progress-bar-container {
    width: 100%;
  text-align: center;
  display: block;
}

.progress {
  /* background: rgba(0, 0, 0, 0.25); */
  background: rgba(204, 204, 204, 0.3);
  /* border-radius: 6px; */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  /* background-image: 
  linear-gradient(to right, 
    #4cd964, #5ac8fa, #007aff, 
    #5856d6, #ff2d55); */
}

.progress-bar3 {
  height: 24px;
  width: 0; 
  transition: 0.4s linear;  
  transition-property: width, background-color;
}

.progress-animation{
    animation-name: progressAnimationStrike !important;
    animation-fill-mode: backwards;
}

@keyframes progressAnimationStrike {
    0% { width: 0 }
    100%   { width: 100% }
}
