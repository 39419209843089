.sentence-crossed-out{
    opacity: 0.5;
    transition: opacity 0.5s;
    overflow: hidden;
}
.sentence-crossed-out .text-p::before{
    content:"";
    border: 2px solid rgba(255,255,255,0.3);
    position: absolute;
    top: 40px;
    left: -50px;
    width: 120%;
    rotate: -45deg;
}