.zoomInAndOut{
    animation: zoomInAndOut 1s infinite;
  }
@keyframes zoomInAndOut {
0%{
    transform: scale(1);
}
50%{
    transform: scale(1.2);
}
100%{
    transform: scale(1);
}
}


.timeOverAnimation{
    animation: timeOverAnimation 2s forwards !important;
}
@keyframes timeOverAnimation {
    0%{
      transform: scale(1);
    }
    100%{
      transform: scale(0) rotate(360deg);
      opacity: 0;
    }
  }