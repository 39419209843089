.mircophone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  /* overflow: hidden; */
}
.microphone-icon-container {
  @apply relative cursor-pointer text-neutral-500 dark:text-white dark:bg-black border-solid border-2 border-neutral-200 dark:border-white rounded-full p-4 w-20 h-20;
}
.microphone-icon-container.listening {
  @apply bg-primary text-white border-primary-light border-2;
}
.microphone-icon-container.listening::before {
  width: 90px;
  height: 90px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.4);
  border-radius: 50%;
  animation: listening infinite 1.5s;
}
.microphone-icon-container.listening::before {
  @apply border-primary-light border-2;
}
@keyframes listening {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  95% {
    opacity: 0.05;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }
}
.microphone-icon {
  width: 100% !important;
  height: 100% !important;
}
.microphone-status {
  font-size: 17px;
  margin-right: 20px;
}
.btn {
  position: relative;
  border: none;
  padding: 14px;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 5px #ffffff1a;
  z-index: 90;
  transition: all 0.5s;
}
.btn {
  @apply text-neutral-500 dark:text-white border-solid border-2 border-neutral-200 dark:border-white;
}
.microphone-result-container {
  text-align: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.microphone-result-text {
  margin-bottom: 30px;
  width: 70vw;
  overflow-y: auto;
}
.microphone-reset {
  display: flex;
  align-items: center;
}

.microphone-icon-container.start-listening-animation::before{
  @apply bg-primary
}
.microphone-icon-container.start-listening-animation::before{
  animation: startListeningAnimation forwards 1s; 
}

@keyframes startListeningAnimation {
  0%{ opacity: 0; transform: translate(-50%, -50%) scale(1);}
  50%{ opacity: 0.4; transform: translate(-50%, -50%) scale(1.2);}
  100%{ opacity: 0; transform: translate(-50%, -50%) scale(1.2);}
}