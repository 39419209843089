.ReactModal__Overlay{
    @apply !bg-neutral-900/90  px-6 py-8 z-[200]
}
.modal-setting-avatar{
    @apply relative  bg-white dark:bg-neutral-800 dark:text-white  pb-16 h-[90vh] outline-none rounded-md overflow-hidden
}

.modal-setting-avatar .btn-close-modal{
    @apply absolute bottom-0 right-0 bg-primary py-3 px-4 rounded-tl-md rounded-br-md text-white
}

.icons-wrapper{
    max-height: calc(100vh - 350px);
}