
@keyframes u-shape {
    100% {
        offset-distance: 100%;
    }
}
@keyframes rotateStar {
 from{transform: rotate(0deg);}
 to{transform: rotate(-360deg);}
}

@keyframes shine {
    0%{color: gold;}
    50%{color: white}
    100%{color: gold;}
}

.font-size-animation{
    animation: fontSizeAnimation 3s infinite;
  }
  
  @keyframes fontSizeAnimation {
    0%{ font-size: auto;}
    50%{  font-size: 22px;}
    100%{  font-size: auto;}
  }


.star{
    width: 30px;
    height: 30px;
    z-index: 200;
    transition: 1s;
    color: gold;
    opacity: 0;
}
.star.star-explode{
    animation: starExplode 2s forwards, u-shape 4s forwards, rotateStar infinite linear 2s, shine 1s forwards;
    z-index: -1 !important;
    opacity: 0;
    width: 700px;
    height: 700px;
}

.star-fly-u-shape{
    animation: u-shape 2s forwards, rotateStar infinite linear 2s;
    opacity: 1;
    left: auto !important;
    bottom: auto !important;
}
.star-shine-and-rotate{
    animation: u-shape 2s forwards, rotateStar infinite linear 5s; 
}

/* 1 star */
.single-star-path{
    offset-path: path("M 0,0    C 0,-200 0,-240   0,20");    
}

/* 2 stars */
.star-1-path {  offset-path: path("M 0,0    C -10,-200 -50,-240      -30,0");    }
.star-2-path {  offset-path: path("M 0,0    C 10,-200 50,-240       30,0");    }

/* 3 stars */
.star-3-path {  offset-path: path("M 0,0    C 0,-200 0,-240      0, 0");  }

/* 5 stars */
.star-4-path {  offset-path: path("M 0,0    C 10,-200 70,-240       60, 0");    }
.star-5-path {  offset-path: path("M 0,0    C 10,-200 -70,-240      -60, 0");    }

/* 7 stars */
.star-6-path {  offset-path: path("M 0,0    C 10,-200 70,-240       -60, 25");    }
.star-7-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    -30, 25");    }

/* 10 stars */
.star-8-path {  offset-path: path("M 0,0    C 20,-240 90,-240     0, 25");    }
.star-9-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    30, 25");    }
.star-10-path {  offset-path: path("M 0,0    C 20,-240 90,-240     60, 25");    }

/* 15 stars */
.star-11-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    -60,50");    }
.star-12-path {  offset-path: path("M 0,0    C 20,-240 90,-240     -30, 50");    }
.star-13-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    0, 50");    }
.star-14-path {  offset-path: path("M 0,0    C 20,-240 90,-240     30, 50");    }
.star-15-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    60, 50");    }

/* 20 stars */
.star-16-path {  offset-path: path("M 0,0    C 20,-240 90,-240     -60, 75");    }
.star-17-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    -30, 75");    }
.star-18-path {  offset-path: path("M 0,0    C 20,-240 90,-240     0, 75");    }
.star-19-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    30, 75");    }
.star-20-path {  offset-path: path("M 0,0    C 20,-240 90,-240     60, 75");    }


/* 30 stars */
.star-21-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    -60, 100");    }
.star-22-path {  offset-path: path("M 0,0    C 20,-240 90,-240     -30, 100");    }
.star-23-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    0, 100");    }
.star-24-path {  offset-path: path("M 0,0    C 20,-240 90,-240     30, 100");    }
.star-25-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    60, 100");    }

.star-26-path {  offset-path: path("M 0,0    C 20,-240 90,-240     -60, 125");    }
.star-27-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    -30, 125");    }
.star-28-path {  offset-path: path("M 0,0    C 20,-240 90,-240     0, 125");    }
.star-29-path {  offset-path: path("M 0,0    C 20,-240 -90,-240    30, 125");    }
.star-30-path {  offset-path: path("M 0,0    C 20,-240 90,-240     60, 125");    }