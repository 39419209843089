.dashboard-table{
    @apply bg-primary rounded-md overflow-hidden
}

.dashboard-table thead th{
    @apply h-[42px]
}
.dashboard-table tbody tr:nth-child(odd){
    @apply bg-neutral-700
}
.dashboard-table tbody td{
    @apply min-w-[34px] max-w-[85px]
}



/* MODAL */

.ReactModal__Overlay{
    @apply !bg-neutral-900/90  px-6 py-8 z-[200]
}
.modal-student-info{
    @apply relative  bg-white dark:bg-neutral-800 dark:text-white rounded-md  pb-16 px-2 min-h-[90vh] outline-none
}

.modal-student-info .dashboard-table th{
    @apply pb-2
}
.modal-student-info .dashboard-table th:nth-child(5),
.modal-student-info .dashboard-table td:nth-child(5){
    @apply border-l-2 border-white/50 pl-2
}
.modal-student-info .dashboard-table td{
    @apply px-4 py-2
}

.modal-student-info .btn-close-modal{
    @apply absolute bottom-0 right-0 bg-primary py-3 px-4 rounded-tl-md rounded-br-md text-white
}