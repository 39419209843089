.gap-span{
    border-radius: 5px;
    animation: animateCorrectAnswer 2s forwards;
    padding: 5px;
}

@keyframes animateCorrectAnswer {
    0%{
        background: auto;
        color: auto;
        text-decoration: none;
    }
    50%{
        background: green;
        color: #fff;
        text-decoration: none;
    }
    100%{
        background: auto;
        color: auto;
        text-decoration: none;
    }
}

.fade-in-animation{
    opacity: 0;
    animation: fadeInAnimation 0.5s forwards;
}

@keyframes fadeInAnimation {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

/* .zoom-animation{
    animation: imgZoomAnimation 7s forwards;
}

@keyframes imgZoomAnimation {
    0%{transform: scale(1);}
    100%{transform: scale(1.2);}
} */


.fade-out{
    animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
    0%{opacity: 1;}
    100%{opacity: 0;}
}


.multiple-btn-wrapper{
    @apply absolute bottom-2 left-0 right-0 px-2
}
.multiple-btn{
    @apply flex w-full bg-primary-dark justify-center text-white  rounded-md p-2 text-2xl
}

.multiple-btn.clicked{
    animation: markAsClicked 0.7s forwards;
}

@keyframes markAsClicked {
    0%{opacity: 1;}
    100%{opacity: 0.2;}
}

.skipper-btn{
    @apply !absolute bottom-[10px] left-[10px]
}
