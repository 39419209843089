.tabs-bar{
    @apply  !bg-white h-[70px] w-full text-white fixed bottom-0  grid grid-cols-12 items-center 
}
.tabs-bar .tab{
    @apply col-span-4 grid justify-center items-center text-center w-full h-full text-white z-[100] bg-primary-light
}
.tabs-bar .tab.active{
    @apply  text-white bg-primary
}

.tabs-bar .tab .MuiSvgIcon-root{
    @apply  w-7 h-7
}
.tabs-bar .tab.active .MuiSvgIcon-root {
    @apply dark:text-white 
}

.tabs-bar .tab .label{
    @apply block dark:text-white
}
.tabs-bar .tab.active .label{
    @apply  font-medium
}