.activity-sub-tabs{
    @apply bg-primary fixed bottom-[70px] left-0 w-full z-[200] h-[70px] grid items-center justify-center py-3 
}

.activity-sub-tabs .tabs-wrapper{
    @apply w-[60vw] text-white rounded-lg grid grid-cols-2 justify-center
}


.activity-sub-tabs .tabs-wrapper .tab{
    @apply grid justify-center items-center py-1 duration-500 text-white dark:text-white cursor-pointer bg-white/20
}
.activity-sub-tabs .tabs-wrapper .tab:nth-child(1){
    @apply border-r-[1px] border-white rounded-l-lg
}
.activity-sub-tabs .tabs-wrapper .tab:nth-child(2){
    @apply border-l-[1px] border-white rounded-r-lg
}

.activity-sub-tabs .tabs-wrapper .tab.active{
    @apply  bg-white text-primary 
}


/* Subtract navbar height + student avatar and name banner + bottom tab bars  */
/*  72 + 53 + 70 + 70 = 265px*/
.activity-user-tasks-done{
    max-height: calc(100vh - 265px); 
}
