.sr-assign-option-tabs{
    @apply w-[100vw] h-[100vh] lg:px-[20%]
}
.sr-assign-option-tabs  .MuiBox-root{
    @apply py-4 px-2
}

.sr-assign-option-tabs .tabs-bar{
    @apply flex justify-center items-end z-[300]  fixed bottom-0 left-0 w-full h-[50px] px-0 !py-0 lg:px-[20%];
}

.sr-assign-option-tabs .MuiTabs-flexContainer{
    @apply flex justify-center
}
.sr-assign-option-tabs .tabs-bar .tabs-wrapper {
    @apply  justify-center flex h-full w-full;
}

.sr-assign-option-tabs .tabs-bar .tabs-wrapper button{
    @apply flex w-[50%] justify-center items-center px-4 font-bold bg-primary text-white
}
.sr-assign-option-tabs .tabs-bar .tabs-wrapper button.active{
    @apply bg-white text-primary border-2 border-white
}

.sr-assign-option-tabs .tab-content{
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 0;
    padding: 10px;
}
.sr-assign-option-tabs .tab-content.tab-visible{
    opacity: 1;
    z-index: 200;
}

.sr-ao-option-heading.letter-A{font-size: 26px; font-family: serif;}
.sr-ao-option-heading.letter-B{font-family: Arial;}
.sr-ao-option-heading.letter-C{font-family: Helvetica;}
.sr-ao-option-heading.letter-D{letter-spacing: 0px; font-size: 26px; font-family: 'Courier New', Courier, monospace;}
.sr-ao-option-heading.letter-E{font-size: 28px; font-family: 'Times New Roman', Times, serif;}
.sr-ao-option-heading.letter-F{font-family: Verdana, Geneva, Tahoma, sans-serif;}
.sr-ao-option-heading.letter-G{font-size: 28px; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif}
.sr-ao-option-heading.letter-H{font-size: 28px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;}

.option-crossed-out{
    opacity: 0.5;
    transition: opacity 0.5s;
    overflow: hidden;
}
.option-crossed-out .text-p::before{
    content:"";
    border: 2px solid rgba(255,255,255,0.3);
    position: absolute;
    top: 125px;
    left: -50px;
    width: 120%;
    rotate: -45deg;
}