
.task-card-done-sash{
    @apply absolute -right-20 top-8 z-[109] py-1 px-2 text-white flex items-center justify-center w-[320px] 
}
.task-card-done-sash{
    transform: rotate(30deg);
}

.task-card-type-icon{
    @apply  bg-secondary rounded-br-full text-white flex items-center w-[50px] h-[50px]  p-1 pl-[6px] pb-[16px]
}
.task-card-type-icon .MuiSvgIcon-root{
    width: 27px;
    height: 27px;
}

.zoom-in-and-out{
    z-index: 90;
    animation: zoomInAndOut 10s infinite;
}

@keyframes zoomInAndOut {
    0%{transform: scale(1);}
    50%{transform: scale(1.1);}
    100%{transform: scale(1);}
}


.task-card .MuiSvgIcon-root.type-icon{
    font-size: 24px !important;
}

.search-results .MuiSvgIcon-root.type-icon,
.dashboard .MuiSvgIcon-root.type-icon{
    width: 18px !important;
    height: 18px !important;
}

.dashboard .lazy-load-image-background{
    display: grid !important;
}