@tailwind components;

@layer components {
  .form-label {
    @apply block mb-2 font-medium text-gray-900 dark:text-white;
  }
  .form-input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-200 focus:border-blue-200 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-200 dark:focus:border-blue-200;
  }
  .form-remember {
    @apply w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-blue-200 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800;
  }
  .checkbox-section{
    @apply grid grid-cols-12 px-1
  }
  .checkbox-wrapper{
    @apply col-span-4 flex items-center justify-center
  }
  .checkbox-label{
    @apply dark:text-white mr-1
  }
  .form-checkbox-input{
    @apply w-5 h-5
  }
  .form-submit-btn {
    @apply duration-500 text-white !bg-secondary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-secondary font-medium rounded-md w-full sm:w-auto px-5 py-2.5 text-center  dark:hover:bg-blue-300 dark:focus:ring-blue-200;
  }
  .form-error{
    @apply bg-primary mt-4 p-2 rounded-md text-white text-center
  }
}
